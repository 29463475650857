window.set_cookie = function (name, value, expires, path, domain, secure) {
  // set time, it's in milliseconds
  const today = new Date()
  today.setTime(today.getTime())
  /*
  if the expires variable is set, make the correct
  expires time, the current script below will set
  it for x number of days, to make it for hours,
  delete * 24, for minutes, delete * 60 * 24
  */
  if (expires) {
    expires = expires * 1000 * 60 * 60 * 24
  }
  const expires_date = new Date(today.getTime() + (expires))

  document.cookie = name + '=' + encodeURIComponent(value) +
      ((expires) ? ';expires=' + expires_date.toGMTString() : '') +
      ((path) ? ';path=' + path : '') +
      ((domain) ? ';domain=' + domain : '') +
      ((secure) ? ';secure' : '')
}

window.get_cookie = function (name) {
  const start = document.cookie.indexOf(name + '=')
  const len = start + name.length + 1
  if ((!start) && (name !== document.cookie.substring(0, name.length))) {
    return null
  }
  if (start === -1) return null
  let end = document.cookie.indexOf(';', len)
  if (end === -1) end = document.cookie.length
  return unescape(document.cookie.substring(len, end))
}

window.delete_cookie = function (name, path, domain) {
  if (window.get_cookie(name)) document.cookie = name + '=' + ((path) ? ';path=' + path : '') + ((domain) ? ';domain=' + domain : '') + ';expires=Thu, 01-Jan-1970 00:00:01 GMT'
}
